import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import classes from "./AddApplication.module.css";
import "../../styles/button.less";
import {
  Button,
  Col,
  Row,
  Checkbox,
  Form,
  Input,
  Space,
  Radio,
  RadioChangeEvent,
  Modal,
  Upload,
  notification,
} from "antd";
import { AiOutlinePaperClip, AiOutlineDelete } from "react-icons/ai";
import Modal1 from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import message from "../../img/message.png";
import infoIcon from "../../img/info.png";
import pclip from "../../img/pclip.png";
import download from "../../img/downblue.png"
import jwt_decode from "jwt-decode";
import axios from "axios";
import { saveAs } from "file-saver";
notification.config({
  placement: "top",
});

interface TokenPayload {
  sub: string;
  jti: string;
  iat: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
  exp: number;
  iss: string;
  aud: string;
}
interface User {
  email: string;
  attributes: {
    birthyear: string[];
    fathername: string[];
    firstname: string[];
    mothername: string[];
    taxid: string[];
    userid: string[];
  };
  createdTimestamp: number;
  disableableCredentialTypes: string[];
  emailVerified: boolean;
  enabled: boolean;
  firstName: string;
  id: string;
  company_name: string;
  lastName: string;
  notBefore: number;
  requiredActions: string[];
  totp: boolean;
  username: string;
}

interface Address {
  address: string;
  address_number: string;
  addresses: string;
  location: string;
  tax_office: string;
  valid_address: boolean;
  zipcode: string;
}

const AddApplication = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [select, setSelect] = useState<string>("");

  const [value, setValue] = useState(1);
  const [user, setUser] = useState<User | null>(null);
  const [address, setAddress] = useState<Address | null>(null);
  const [nameId, setNameId] = useState<string>("");
  const [applicationId, setApplicationId] = useState<string | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const [file1, setFile1] = useState<File | undefined | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [generalError, setGeneralError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [authError, setAuthError] = useState<boolean>(false);

  const handleFileChange = (file: File, setter: Function) => {
    form.setFieldValue('file1', file)
    setter(file);
  };

  const handleFileCancel = (setter: Function) => {
    form.setFieldValue('file1', null)
    setter(null);
  };

  const [notificationVisible, setNotificationVisible] = useState(false);

  const showNotification = () => {
    setNotificationVisible(true);
    setLoading(false)
  };

  const closeNotification = () => {
    setNotificationVisible(false);
    navigate("/dashboard");
  };

  const navigateToProfile = () => {
    closeNotification();
    navigate("/dashboard");
  };

  const openFailNotification = () => {
    notification.open({
      message: errorMessage === "ΙnvalidAddress" ? "Δεν ανήκει η διεύθυνση σε αυτές του προγράμματος" : `Δυστυχώς, η αίτησή σας δεν καταχωρήθηκε επιτυχώς!`,
      description: "Παρακαλούμε ξαναπροσπαθήστε ή επικοινωνήστε μαζί μας.",
      duration: 0,
      placement: "top",
      type: "error",
    });
  };

  const onFinish = async (values: any) => {
    setFormSubmitted(true);
    setGeneralError(false);
    setErrorMessage("")
    try {
      await form.validateFields();
    } catch (error) {}

    setLoading(true);
    
    const requestBody:any = {
      email: values.email,
      firstname: user?.attributes?.firstname[0] ? user?.attributes?.firstname[0] : "",
      lastname: user?.lastName ? user?.lastName : "",
      fathername: user?.attributes.fathername[0] ? user?.attributes?.fathername[0] : "",
      company_name: values.company_name,
      tax_id: values.afm,
      phone1: values.home_phone_number,
      phone2: values.mobile_phone_number ? values.mobile_phone_number : "",
      address: values?.address ?? initialValues?.address ?? "",
      address_number:
        values?.address_number ?? initialValues?.address_number ?? "",
      zipcode: values?.zipcode ?? initialValues?.zipcode ?? "",
      location: address?.location ?? initialValues?.location ?? "",
      activity_id: values.activity,
      microsoft_username: values.user_id,
      comments: values.comment,
    };

    const formData = await new FormData();

    Object.keys(requestBody).map((key) => formData.append(key, requestBody[key]))

    await formData.append(
      "id",
      (await window.localStorage.getItem("nameID"))
        ? await window.localStorage.getItem("nameID")!
        : ""
    );
    if (file1) {
      await formData.append("file1", file1);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/SubmitAdmin`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setApplicationId(response.data);
      onReset();
      setLoading(false);
      setFormSubmitted(false);
      showNotification();
      
    } catch (error: any) {
      console.error("Error:", error);
      openFailNotification();
      setErrorMessage(error.response.data.detail)
      setLoading(false)
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setGeneralError(true);
    console.log("Failed:", errorInfo);
  };
  const onChangeRadio = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const registerId = async (body: any) => {
    await fetch(`${process.env.REACT_APP_SERVER_NAME}/api/Auth/Register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (response) => {
        // console.log("tokenEATAMS: ", response);
        if (
          response.access_token &&
          typeof response.access_token === "string"
        ) {
          await window.localStorage.setItem(
            "tokenEATAMS",
            response.access_token
          );
          const token = response.access_token;
          console.log("tokenn: ", token);
          if (!token) {
            return window.localStorage.getItem("access_token");
          }
          const decodedToken = jwt_decode<TokenPayload>(token);
          const nameIdentifier =
            decodedToken[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ];
          // console.log(nameIdentifier);
          await window.localStorage.setItem("nameID", nameIdentifier);
          setNameId(nameIdentifier);
          return nameIdentifier;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return null;
  };

  const onReset = () => {
    form.resetFields();
    setSelect("");
    handleFileCancel(setFile1);
  };

  const { isOpenSearch, toggleSearch } = useModal();

  const [initialValues, setInitialValues] = useState({
    company_name: "",
    afm: "",
    activity: null,
    address: "",
    address_number: "",
    zipcode: "",
    location: "",
    region: "",
    doy: "",
    home_phone_number: "",
    mobile_phone_number: "",
    email: "",
    user_id: "",
    comment: "",
    tax_office: "",
    file_list: [],
    agreement: false,
    agreement2: false,
    agreement3: false,
  });

  const handleDownload = () => {
    saveAs('file/Ypethini_dilosi_de_minimis_14-11.doc', 'Ypethini_dilosi_de_minimis_14-11.doc')   
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Η αίτησή σας καταχωρήθηκε επιτυχώς!"
        visible={notificationVisible}
        onCancel={closeNotification}
        footer={[
          <Button key="profile" type="primary" onClick={navigateToProfile}>
            Προβολή Προφίλ
          </Button>,
        ]}
      >
        <p>
          Μπορείτε να ενημερωθείτε για την εξέλιξή του αιτήματος στο προφίλ σας,
          ευχαριστούμε!
        </p>
      </Modal>

      <HelmetComponent
        title={t("Αναπτυξιακή Αθήνας") || undefined}
        descrition={t("Αναπτυξιακή Αθήνας") || undefined}
      />
      <div className={classes.pageContainer}>
        {/* step={0} = Contact type modal */}
        <Modal1 isOpen={isOpenSearch} toggle={toggleSearch} step={0}>
          <div className={classes.overlay_header}>
            <button className={classes.cross} onClick={toggleSearch}>
              x
            </button>
          </div> 
          <div className={classes.messageContainer}>
            <img src={message} alt="message" />
          </div>
          <div className={classes.message}>
            <p>
              Σας ευχαριστούμε που επικοινωνήσατε μαζί μας. Θα σας απαντήσουμε
              το συντομότερο δυνατό.
            </p>
          </div>
        </Modal1>
        <div className={classes.container}>
          
            <div className={classes.fcontainer}>
              <Form
                form={form}
                labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 8 }}
                wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 13, xl: 11 }}
                layout="horizontal"
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
              >
                <div className={classes.formItemContainer}>
                  <Form.Item label=" " name=" " className={classes.formItem}>
                    <h2>Αίτηση Συμμετοχής Ωφελούμενων</h2>
                    <span className={classes.subName}>
                      * Τα πεδία/ δικαιολογητικά με αυτή την ένδειξη είναι
                      υποχρεωτικά.
                    </span>
                  </Form.Item>

                  <Form.Item
                    label="Επωνυμία επιχείρησης"
                    name="company_name"
                    className={classes.formItem}
                    rules={[
                      { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                    ]}
                    colon={false}
                    required={false}
                  >
                    <Input className={classes.input}/>
                  </Form.Item>

                  <Form.Item
                    label="ΑΦΜ"
                    name="afm"
                    initialValue={user ? window.localStorage.getItem("afm") : ""}
                    className={classes.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Το πεδίο είναι υποχρεωτικό",
                      },
                    ]}
                    colon={false}
                    required={false}
                  >
                    <Input className={classes.input}/>
                  </Form.Item>

                  <Form.Item
                    label="Οδός"
                    name="address"
                    className={classes.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Το πεδίο είναι υποχρεωτικό",
                      },
                    ]}
                    colon={false}
                    required={false}
                  >
                    <Input className={classes.input}/>
                  </Form.Item>
                  <Form.Item
                    label="Αριθμός"
                    name="address_number"
                    className={classes.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Το πεδίο είναι υποχρεωτικό",
                      },
                    ]}
                    colon={false}
                    required={false}
                  >
                    <Input className={classes.input}/>
                  </Form.Item>

                  <Form.Item
                    label="ΤΚ"
                    name="zipcode"
                    className={classes.formItem}
                    rules={[
                      { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                    ]}
                    colon={false}
                    required={false}
                  >
                    <Input className={classes.input}/>
                  </Form.Item>

                  <Form.Item
                    label="Δραστηριότητα Επιχείρησης"
                    name="activity"
                    className={classes.formItemActivity}
                    colon={false}
                    required={false}
                  >
                    <Radio.Group
                      onChange={onChangeRadio}
                      value={value}
                      style={{ width: "100%" }}
                      className={classes.radioGroup}
                    >
                      <Space
                        direction="vertical"
                        className={classes.RadioContainer}
                      >
                        <Radio className={classes.radio} value={1}>
                          Τεχνολογίες Πληροφορικής & Επικοινωνίας (ΤΠΕ)
                        </Radio>
                        <Radio className={classes.radio} value={2}>
                          Εφοδιαστική Αλυσίδα / Logistics
                        </Radio>
                        <Radio className={classes.radio} value={3}>
                          Οικονομία - Διοίκηση
                        </Radio>
                        <Radio className={classes.radio} value={4}>
                          Πωλήσεις - Marketing
                        </Radio>
                        <Radio className={classes.radio} value={5}>
                          Τεχνικά Επαγγέλματα
                        </Radio>
                        <Radio className={classes.radio} value={6}>
                          Τρόφιμα - Αγροδιατροφή
                        </Radio>
                        <Radio className={classes.radio} value={7}>
                          Τουρισμός
                        </Radio>
                        <Radio className={classes.radio} value={8}>
                          Περιβάλλον - Ανακύκλωση - Ενέργεια
                        </Radio>
                        <Radio className={classes.radio} value={9}>
                          Υγεία
                        </Radio>
                        <Radio className={classes.radio} value={10}>
                          Άλλο
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Σταθερό Τηλέφωνο*"
                    name="home_phone_number"
                    className={classes.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Το πεδίο είναι υποχρεωτικό",
                      },
                    ]}
                    colon={false}
                    required={false}
                  >
                    <Input className={classes.input} />
                  </Form.Item>

                  <Form.Item
                    label="Κινητό Τηλέφωνο"
                    name="mobile_phone_number"
                    className={classes.formItem}
                  >
                    <Input className={classes.input} />
                  </Form.Item>

                  <Form.Item
                    label="Email*"
                    name="email"
                    className={classes.formItem}
                    rules={[
                      {
                        type: "email",
                        message: "Δεν έχει συμπληρωθεί σωστά. Ξαναπροσπαθήστε",
                      },
                      {
                        required: true,
                        message: "Το πεδίο είναι υποχρεωτικό",
                      },
                    ]}
                    colon={false}
                    required={false}
                  >
                    <Input className={classes.input} />
                  </Form.Item>

                  <Form.Item
                    label="Επιθυμητό Username*"
                    name="user_id"
                    className={classes.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Το πεδίο είναι υποχρεωτικό",
                      },
                    ]}
                    colon={false}
                    required={false}
                    // suffix={}
                  >
                    <Input className={classes.input} suffix={
                      <h3 style={{ marginBottom: '1.5rem', fontSize: '1.15rem' }}>
                        @digitaltools.thisisathens.org
                      </h3>}/>
                  </Form.Item>

                  <Form.Item
                    label="Σχόλιο"
                    name="comment"
                    className={classes.formItem}
                  >
                    <Input.TextArea
                      className={classes.inputTextArea}
                      style={{ height: 120, resize: "none" }}
                      maxLength={1200}
                    />
                  </Form.Item>

                  <Form.Item label="Υπεύθυνη Δήλωση*" valuePropName="file_list" name="file1" className={classes.formItem}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Το πεδίο είναι υποχρεωτικό",
                    //   },
                    // ]}
                    rules={[
                      {
                        validator: (_, value) => 
                          value
                            ? Promise.resolve()
                            : Promise.reject(new Error("Το πεδίο είναι υποχρεωτικό")),
                      },
                    ]}
                    colon={false}
                    required={false}
                  >
                    <h3 style={{ marginBottom: 0 }}>
                      Παρακαλούμε προσθέσετε τα ακόλουθα δικαιολογητικά για να
                      ολοκληρώσετε την αίτησή σας:
                    </h3>
                    <h4 style={{marginBottom: '1.5rem'}}>
                      * H Υπεύθυνη Δήλωση πρέπει να υπογραφεί μέσω <a href="https://www.gov.gr/ipiresies/polites-kai-kathemerinoteta/psephiaka-eggrapha-gov-gr/psephiake-bebaiose-eggraphou" target="__blank">gov.gr</a>
                    </h4>

                    <Button className={`${classes.formItem2} no-ripple`} onClick={() => handleDownload()}>
                      <img src={download} alt="Paper clip" style={{marginLeft: "-1.1rem", marginRight: '0.35rem'}}/>
                      Λήψη υπεύθυνης δήλωσης 
                    </Button>

                    <Upload
                      className={classes.formItem2}
                      action="/upload.do"
                      listType="text"
                      beforeUpload={() => false}
                      onChange={(info) => {
                        const { fileList } = info;
                        const nativeFile = fileList[0].originFileObj as File;
                        handleFileChange(nativeFile, setFile1);
                      }}
                      showUploadList={false}
                    >
                      <Button className={`${classes.formItem2} no-ripple`}>
                        <img src={pclip} alt="Paper clip" />
                        Υπεύθυνη Δήλωση Ν. 1599/1986 για το ύψος των <br />
                        ενισχύσεων βάσει του καθεστώτος De Minimis
                      </Button>
                    </Upload>
                    {file1 && (
                      <span className={classes.requiredFile}>
                        <br />
                        <br />
                        <AiOutlinePaperClip /> {file1.name}
                        <Button
                          size="small"
                          onClick={() => handleFileCancel(setFile1)}
                          style={{ marginLeft: 10, border: 0 }}
                        >
                          <AiOutlineDelete />
                        </Button>
                      </span>
                    )}
                  </Form.Item>
                </div>

                <br />
                <div className={classes.formItemContainer}>
                  <div className={classes.checkboxContainer}>
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      wrapperCol={{ span: 24 }}
                      style={{ marginBottom: 40 }}
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(new Error("Το πεδίο είναι υποχρεωτικό")),
                        },
                      ]}
                    >
                      <Checkbox className={classes.checkbox}>
                        Δηλώνω ότι συγκατατίθεμαι για τη συλλογή, τη χρήση και την
                        επεξεργασία των παραπάνω στοιχείων και προσωπικών
                        δεδομένων, για τους σκοπούς της Αίτησης και σύμφωνα με
                        τους όρους και τις προϋποθέσεις που θέτει η Αρχή
                        Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      name="agreement3"
                      valuePropName="checked"
                      wrapperCol={{ span: 24 }}
                      style={{ marginBottom: 60 }}
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(new Error("Το πεδίο είναι υποχρεωτικό")),
                        },
                      ]}
                    >
                      <Checkbox className={classes.checkbox}>
                        Δηλώνω ότι συμφωνώ με τους όρους και τις προϋποθέσεις του
                        Προγράμματος και συγκατατίθεμαι για την υποβολή της
                        Αίτησης στα πληροφοριακά συστήματα του Φορέα, στο πλαίσιο
                        της Συμμετοχής μου.
                      </Checkbox>
                    </Form.Item>
                  </div>
                </div>

                <div className={classes.formItemContainer1}>
                  <Row>
                    <Col xs={24} sm={24} md={3} lg={2} xl={2}>
                      <img src={infoIcon} alt="info" />
                    </Col>
                    <Col xs={24} sm={24} md={21} lg={22} xl={22}>
                      <p className={classes.alert}>
                        Το εν λόγω πρόγραμμα εντάσεται στα πλαίσια του κανόνα De
                        Minimis και χρηματοδοτείται μέσω του Πληροφοριακού
                        Συστήματος Σώρευσης Κρατικών Ενισχύσεων Ήσσονος Σημασίας
                        (Sorefsis.gr), με ποσό ενίσχυσης 140,12 € (τιμή με ΦΠΑ).
                      </p>
                    </Col>
                  </Row>
                </div>

                <div className={classes.buttons}>
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Space>
                      <Row>
                        <Col>
                          <Button
                            htmlType="button"
                            onClick={onReset}
                            className={classes.reset}
                          >
                            Καθαρισμός
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className={classes.submit}
                            htmlType="submit"
                            disabled={loading}
                          >
                            Υποβολή Αίτησης
                          </Button>
                        </Col>
                      </Row>
                    </Space>
                  </Form.Item>
                  {generalError && (
                    <div className={classes.warning}>
                      Παρακαλούμε συμπληρώστε όλα τα απαραίτητα πεδία!
                    </div>
                  )}
                  {errorMessage !== "" && (
                    <div className={classes.warning}>
                      {errorMessage === "ΙnvalidAddress" && "Δεν ανήκει η διεύθυνση σε αυτές του προγράμματος"}
                      {errorMessage == "UserCreateError" && "Δεν μπορεί να δημιουργηθεί ο χρήστης"}
                      {errorMessage == "FatalError" && "Απέτυχε η καταχώρηση"}
                    </div>
                  )}
                </div>
              </Form>
            </div>

        </div>
      </div>
    </>
  );
};

export default AddApplication;
