import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Row, Col, Input, Form, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "./LoginUser.module.css";
import LoginForm from "../../routes/Login/LoginForm";
import ggps from "../../img/ggps.png";
import logo from "../../img/logo.png";
import etpa from "../../img/ETPA.png";
import { AuthContext } from "../../context/AuthContext";
import { Button, Divider, notification, Space } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import type { NotificationPlacement } from "antd/es/notification/interface";

const LoginUser = () => {
  const [afm, setAfm] = useState<string>("");
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const { isLoggedOut } = useContext(AuthContext);

  const handleNavigation = async () => {
    await window.localStorage.clear();
    await window.localStorage.setItem("afm", afm);
    form.validateFields().then(() => {
      if (checkboxChecked) {
        navigate("/login");
      } else {
        // Show error message or handle the case where checkbox is not checked
      }
    });
  };

  const handleAfmChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setAfm(inputValue);
  };

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: (
        <span className={classes.customNotification}>
          Αποσυνδεθήκατε επιτυχώς!
        </span>
      ),
      placement,
      icon: <LogoutOutlined />,
      className: `${classes.customNotification}`,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLoggedOut) {
      openNotification("top");
    }
  }, []);

  const mockError = false 

  return (
    <>
      {contextHolder}
      <div className={classes.container}>
        {/* <div className={classes.placeHolder}>
          Αυτή η σελίδα είναι υπό κατασκευή
          <a>Επιστροφή στην αρχική...</a>
        </div> */}
        <div className={classes.login}>
          <div className={classes.title}>
            <Row>
              <Col xs={24} sm={11} md={11} xl={11}>
                <img src={logo} className={classes.logo} alt="logo" />
              </Col>
              <Col xs={24} sm={13} md={13} xl={13}>
                <h3>Πρόγραμμα Ψηφιακού Μετασχηματισμού Επιχειρήσεων</h3>
              </Col>
            </Row>
          </div>
          <div className={classes.separator}></div>
          <div className={classes.formOuterContainer}>
            <Form
              form={form}
              initialValues={{ afm }}
              onFinish={handleNavigation}
              autoComplete="on"
            >
              <Row className={classes.afmContainer}>
                <Col xl={3}>
                  <p className={classes.afmLabel}>ΑΦΜ:</p>
                </Col>
              </Row>
              <Row>
                <div className={classes.afmLowerContainer}>
                  <Form.Item
                    name="afm"
                    rules={[
                      {
                        required: true,
                        message: "To πεδίο ΑΦΜ είναι υποχρεωτικό",
                      },
                      {
                        len: 9,
                        message: "Το ΑΦΜ πρέπει να αποτελείται από 9 ψηφία",
                      },
                    ]}
                  >
                    <Input
                      className={classes.afmInput}
                      value={afm}
                      onChange={handleAfmChange}
                    />
                  </Form.Item>
                </div>
              </Row>
              {/* <Row>
                <div className={classes.afmLowerContainer}>
                  {!localStorage.getItem("user") ? "Λανθασμένα στοιχεία" : ""}
                </div>
              </Row> */}
              <Row className={classes.afmLowerContainer}>
                <Form.Item
                  name="checkbox"
                  valuePropName="checked"
                  rules={[{ required: true, message: 'Πρέπει να αποδεχτείτε τους όρους.' }]}
                >
                  <Checkbox onChange={(e) => setCheckboxChecked(e.target.checked)}>
                    Αποδέχομαι τους όρους και τις προϋποθέσεις
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row className={classes.loginBtnContainer}>
                <button
                  type="submit"
                  className={classes.loginBtn}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Σύνδεση
                </button>
              </Row>
              <p className={classes.error}>
                {window.localStorage.getItem("error")
                  ? `Το επιλεγμένο ΑΦΜ δεν βρέθηκε!`
                  : ``}
              </p>
            </Form>
          </div>
        </div>

        <img className={classes.etpa} src={etpa} alt="espa" />
      </div>
    </>
  );
};

export default LoginUser;
