import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import classes from "./Dashboard.module.css";
import {
  Col,
  Row,
  Modal,
  Input,
  Table,
  DatePicker,
  Menu,
  Dropdown,
  Tabs,
  Radio,
  Space,
} from "antd";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import editButton from "../../img/edit_button.png";
import exportIcon from "../../img/export.png";
import edit_transparent_blue from "../../img/edit_transparent_blue.png";
import yellow_ellipse from "../../img/yellow_ellipse.png";
import red_ellipse from "../../img/red_ellipse.png";
import green_ellipse from "../../img/green_ellipse.png";
import exclamation from "../../img/exclamation.png";
import search_fill from "../../img/search_fill.png";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import * as XLSX from "xlsx";
import { StyleSheet } from "@react-pdf/renderer";
import {
  pdf,
  Page as PDFPage,
  Text as PDFText,
  StyleSheet as PDFStyleSheet,
} from "@react-pdf/renderer";
import { RangeValue } from "rc-picker/lib/interface";
import type { TableRowSelection } from "antd/es/table/interface";
import type { ColumnsType } from "antd/es/table";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import "dayjs/locale/el";
import locale from "antd/es/date-picker/locale/el_GR";
import { useNavigate } from "react-router";

const { RangePicker } = DatePicker;

type Application = {
  key: React.Key;
  activity: string;
  activity_id: number;
  address: string;
  address_number: string;
  application_code: string;
  comments: string | null;
  company_name: string;
  date_approved: string;
  date_expiration: string;
  date_stopped: string;
  date_submitted: string;
  email: string;
  fathername: string;
  filename: string;
  firstname: string;
  id: string;
  lastname: string;
  location: string;
  microsoft_id: string | null;
  phone1: string;
  phone2: string;
  status: string;
  status_id: number;
  tax_id: string;
};

interface DataType2 {
  key: string;
  email: string;
  date_submitted: string;
  application_code: string; // Change the key property to application_code
  company_name: string;
  tax_id: string;
  status: string;
  id: string;
}

interface Activity {
  id: number;
  name: string;
}

const customNoDataLocale = {
  emptyText: (
    <div className={classes.noData}>
      <img
        src={exclamation}
        alt="exclamation"
        className={classes.exclamation}
      />
      <p>Δεν υπάρχουν εγγραφές</p>
    </div>
  ),
};

function Dashboard() {
  const { t } = useTranslation();

  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>("all");

  const [applications, setApplications] = useState<Application[]>([]);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const token = window.localStorage.getItem("token");
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<DataType2[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const tableRef = useRef<any>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const standardDate = new Date("2025-01-03");
  const totalDaysInYear = 365; // Assuming a non-leap year
  const today = new Date();
  const daysPassed = Math.floor(
    (today.getTime() - standardDate.getTime()) / (24 * 60 * 60 * 1000)
  );
  const daysLeft = totalDaysInYear - daysPassed;
  const [placeholder, setPlaceholder] = useState<string>("");

  const navigate = useNavigate()

  const handleSearchInputChange = async (e: any) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.trim() === "") {
      fetchApplications();
      return;
    }

    setIsSearching(true);
    try {
      const parameters = [
        { app_form_code: value },
        { company_name: value },
        { tax_id: value },
      ];

      const promises = parameters.map((params) =>
        axios.get<Application[]>(
          `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Search`,
          { params }
        )
      );

      const searchResults = await Promise.all(promises);

      const combinedResults: Application[] = searchResults.flatMap(
        (result) => result.data
      );

      setApplications(combinedResults);
    } catch (error) {
      console.error("Error fetching SearchAddress:", error);
    } finally {
      setIsSearching(false);
    }
  };
  const onSearch = async (value: string) => {
    setSearchValue(value);
    console.log(value);

    if (value.trim() === "") {
      fetchApplications();
      return;
    }

    setIsSearching(true);
    try {
      const parameters = [
        { app_form_code: value },
        { company_name: value },
        { tax_id: value },
      ];

      const promises = parameters.map((params) =>
        axios.get<Application[]>(
          `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Search`,
          { params }
        )
      );

      const searchResults = await Promise.all(promises);

      const combinedResults: Application[] = searchResults.flatMap(
        (result) => result.data
      );

      setApplications(combinedResults);
    } catch (error) {
      console.error("Error fetching SearchAddress:", error);
    } finally {
      setIsSearching(false);
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType2[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection2: TableRowSelection<DataType2> = {
    onChange: onSelectChange,
    selectedRowKeys,
  };

  const renderStatus = (status: string) => {
    if (status === "Σε εξέλιξη") {
      return (
        <div className={classes.statusContainer}>
          <img src={yellow_ellipse} alt={status} /> <p> Σε εξέλιξη</p>
        </div>
      );
    } else if (status === "Απορρίφθηκε") {
      return (
        <div className={classes.statusContainer}>
          <img src={red_ellipse} alt={status} /> <p> Μη εγκεκριμένη</p>
        </div>
      );
    } else if (status === "Εγκρίθηκε") {
      return (
        <div className={classes.statusContainer}>
          <img src={green_ellipse} alt={status} /> <p> Εγκρίθηκε</p>
        </div>
      );
    } else return status;
  };

  const columns2: ColumnsType<DataType2> = [
    {
      title: "Ημερομηνία Υποβολής",
      dataIndex: "date_submitted",
      key: "date_submitted",
      width: 190,
      sorter: (a, b) => {
        return dayjs(a.date_submitted, "YYYY/MM/DD").isBefore(
          dayjs(b.date_submitted, "YYYY/MM/DD")
        )
          ? 1
          : -1;
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Αριθμός Αίτησης",
      dataIndex: "application_code",
      key: "application_code",
      width: 168,
    },
    {
      title: "Επωνυμία",
      dataIndex: "company_name",
      key: "company_name",
      width: 168,
    },
    {
      title: "ΑΦΜ",
      dataIndex: "tax_id",
      key: "tax_id",
      width: 125,
    },
    // {
    //   title: "Περιοχή",
    //   dataIndex: "id",
    //   key: "areaData",
    //   width: 153,
    //   render: (id: string) =>
    //     areaData[id] === true ? (
    //       <span style={{ color: "red" }}>Εντός Ορίων</span>
    //     ) : (
    //       <span style={{ color: "red" }}>Εκτός Ορίων</span>
    //     ),
    // },
    {
      title: "Κατάσταση Αίτησης",
      dataIndex: "status",
      key: "status",
      width: 215,
      render: (status: string) => {
        if (status === "Σε εξέλιξη") {
          return (
            <div className={classes.statusContainer}>
              <img src={yellow_ellipse} alt={status} /> <p> Σε εξέλιξη</p>
            </div>
          );
        } else if (status === "Απορρίφθηκε") {
          return (
            <div className={classes.statusContainer}>
              <img src={red_ellipse} alt={status} /> <p> Μη εγκεκριμένη</p>
            </div>
          );
        } else if (status === "Εγκρίθηκε") {
          return (
            <div className={classes.statusContainer}>
              <img src={green_ellipse} alt={status} /> <p> Εγκρίθηκε</p>
            </div>
          );
        } else return status;
      },
    },
    {
      title: "Επεξεργασία Αίτησης",
      dataIndex: "actions",
      width: 193,
      key: "actions",
      render: (_, record: DataType2) => (
        <div
          className={classes.editWrap}
          onClick={() =>
            (window.location.href = `/edit/${record.application_code}`)
          }
        >
          <img
            className={classes.editTransparentBlue}
            src={edit_transparent_blue}
            alt=""
          />
        </div>
      ),
    },
  ];

  const columnsModal: ColumnsType<DataType2> = [
    {
      title: "ΑΦΜ",
      dataIndex: "tax_id",
      key: "tax_id",
    },
    {
      title: "Επωνυμία",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Κατάσταση Αίτησης",
      dataIndex: "status",
      key: "status",
    },
  ];

  // const fetchApplications = async () => {
  //   try {
  //     const response = await axios.get<Application[]>('https://eata-admin.dotsoft.gr/eatams/api/ApplicationForm/GetAll');
  //     setApplications(response.data);
  //   } catch (error) {
  //     console.error('Error fetching applications:', error);
  //   }
  // };
  const searchApplications = async () => {
    try {
      const response = await axios.get<Application[]>(
        `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/GetAll`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setApplications(response.data);
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };
  const handleOk = async () => {
    if (!selectedStatus) {
      // If no status is selected, do not proceed with the Axios call
      console.log("Please select a status.");
      return;
    }

    console.log(selectedRows);
    try {
      // Array to store all the Axios promises
      const updatePromises: Promise<any>[] = [];

      // Loop through each selected row and create a separate Axios request for each one
      selectedRows.forEach((row) => {
        if (selectedStatus === "2") {
          setPlaceholder(
            `Αγαπητέ/ή ${
              row.company_name
            },<div><br><div>Θα θέλαμε να σας ενημερώσουμε ότι η αίτησή σας για το πρόγραμμα «Ψηφιακός Μετασχηματισμός Επιχειρήσεων» πληροί τα κριτήρια που έχουν οριστεί για το πρόγραμμα.<div><br></div><div>Θα λάβετε επιπλέον πληροφορίες σχετικά με το επόμενο βήμα της διαδικασίας αίτησης μέσω email στο επόμενο διάστημα.</div><div><br></div><div>Σας ευχαριστούμε για το ενδιαφέρον σας για το πρόγραμμά μας.</div><div><br></div><div>Με εκτίμηση, </div>${"Test"}</div></div>`
          );
        } else if (selectedStatus === "4") {
          setPlaceholder(
            `Αγαπητέ/ή ${
              row.company_name
            },<div><br><div>Δυστυχώς, πρέπει να σας ενημερώσουμε ότι δεν πληροίτε τα απαραίτητα κριτήρια για τη συμμετοχή στο πρόγραμμα «Ψηφιακός Μετασχηματισμός Επιχειρήσεων».<div><br></div><div>Εάν θέλετε να μάθετε περισσότερες πληροφορίες σχετικά με τα κριτήρια της απόρριψης, παρακαλούμε να επικοινωνήσετε μαζί μας και θα σας παρέχουμε όσες πληροφορίες χρειάζεστε.</div><div><br></div><div>Σας ευχαριστούμε για το ενδιαφέρον σας για το πρόγραμμά μας.</div><div><br></div><div>Με εκτίμηση,</div></div>${"Test"}</div>`
          );
        }

        const promise = axios.put<any>(
          `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/UpdateStatus`,
          selectedStatus === "1"
            ? {
                id: row.id,
                status_id: selectedStatus,
              }
            : {
                id: row.id,
                status_id: selectedStatus,
                // email : row.email,
                email: "christoforos@dotsoft.gr",
                body: placeholder,
              },
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        updatePromises.push(promise);
      });

      // Execute all Axios requests concurrently using Promise.all
      const responses = await Promise.all(updatePromises);

      // Process the responses as needed
      responses.forEach((response, index) => {
        console.log(
          `Updated status for row ${selectedRows[index].id}:`,
          response
        );
      });

      closeModal(); // Close the modal after the updates are done
      fetchApplications();

      // Clear selected rows and keys after the updates are done
      setSelectedRowKeys([]);
      tableRef.current.clearSelectedRows(); // If the Table component exposes a method to clear selected rows, use it here
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(applications);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const fileName = "table.xlsx";
    saveAs(new Blob([excelBlob]), fileName);
  };

  const handleExportExcelAndPDF = () => {
    const worksheet = XLSX.utils.json_to_sheet(applications);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    // Convert the data to HTML table
    const tableData = applications.map((app: Application) => {
      return `
      <tr>
        <td>${app.application_code}</td>
        <td>${app.company_name}</td>
        <!-- Add other properties as needed -->
      </tr>
    `;
    });
    const htmlTable = `
    <table>
      <thead>
        <tr>
          <th>Property 1</th>
          <th>Property 2</th>
          <!-- Add other headers as needed -->
        </tr>
      </thead>
      <tbody>
        ${tableData.join("")}
      </tbody>
    </table>
  `;

    // Convert the HTML table to PDF
    const pdfPromise = html2pdf().from(htmlTable).outputPdf();

    pdfPromise.then((excelBlob: any) => {
      const pdfFileName = "table.pdf";
      saveAs(excelBlob, pdfFileName);
    });
  };

  type jsPDFWithPlugin = jsPDF & {
    autoTable: (options: any) => void;
  };
  const handleExportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size) as jsPDFWithPlugin;

    doc.setFontSize(15);

    const headers = [
      [
        "Ημερομηνία Υποβολής",
        "Αριθμός Αίτησης",
        "Επωνυμία",
        "ΑΦΜ",
        "Κατάσταση Αίτησης",
      ],
    ];

    // Use html2canvas to render the table as an image
    let tableElement = null;
    if (selectedTab === "all") {
      tableElement = document.getElementById("tableToExportAll");
    } else if (selectedTab === "inProgress") {
      tableElement = document.getElementById("tableToExport1");
    } else if (selectedTab === "approved") {
      tableElement = document.getElementById("tableToExport2");
    } else if (selectedTab === "notApproved") {
      tableElement = document.getElementById("tableToExport4");
    }

    const data = applications.map((row) => [
      row.date_submitted,
      row.application_code,
      row.company_name,
      row.tax_id,
      row.status,
    ]);

    (doc as any).autoTable({
      head: headers,
      body: data,
      startY: 0,
    });

    if (!tableElement) return;

    html2canvas(tableElement, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Get the dimensions of the image
      const imgWidth = 595.28; // A4 width in points
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Create a new PDF document
      const doc = new jsPDF(orientation, unit, size) as jsPDFWithPlugin;

      // Add the rendered image as a page in the PDF
      doc.addImage(
        imgData,
        "PNG",
        marginLeft,
        80,
        imgWidth - 2 * marginLeft,
        imgHeight
      );

      // Save the PDF
      doc.save("report.pdf");
    });
  };

  const fetchApplications = async () => {
    try {
      const response = await axios.get<Application[]>(
        `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/GetAll`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const nullToEmpty = (value: any) =>
        value === (null || "null") ? "" : value;

        const updatedApplications = await Promise.all(
        response.data.map(async (application) => {
          try {
            const checkAddressResponse = await axios.get<any>(
              `${process.env.REACT_APP_SERVER_NAME}/api/Auth/SearchAddress/${application.id}`
            );

            const areaDataForApplication = checkAddressResponse.data;

            // setAreaData((prevAreaData) => ({
            //   ...prevAreaData,
            //   [application.id]: areaDataForApplication,
            // }));

            const applicationWithNullsHandled: Application = {
              ...application,
              date_submitted: nullToEmpty(application.date_submitted),
              application_code: nullToEmpty(application.application_code),
              company_name: nullToEmpty(application.company_name),
              tax_id: nullToEmpty(application.tax_id),
            };

            return applicationWithNullsHandled;
          } catch (error) {
            console.error("Error fetching SearchAddress:", error);
            return application;
          } finally {
            setIsSearching(false);
          }
        })
      );

      setApplications(updatedApplications);
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };

  useEffect(() => {
    if (searchValue.trim() === "") {
      fetchApplications();
    }
  }, [searchValue]);

  useEffect(() => {
    console.log("selectedRows: ", selectedRows);
    console.log("Role: ", window.localStorage.getItem("userType"));
  }, [selectedRows]);

  const onRangeChange = (
    values: RangeValue<Dayjs>,
    formatString: [string, string]
  ) => {
    if (values) {
      const [start, end] = values;
      const newStartDate = start ? start.startOf("day") : null;
      const newEndDate = end ? end.endOf("day") : null;
      setDateRange([newStartDate, newEndDate]);
    } else {
      setDateRange([null, null]);
    }
  };

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: "Τελευταίες 7 Μέρες", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Τελευταίες 14 Μέρες", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Τελευταίες 30 Μέρες", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Τελευταίες 90 Μέρες", value: [dayjs().add(-90, "d"), dayjs()] },
  ];
  const startDate = dateRange[0] ? dayjs(dateRange[0].toDate()) : null;
  const endDate = dateRange[1] ? dayjs(dateRange[1].toDate()) : null;

  const filteredData = applications.filter((app) => {
    if (!startDate && !endDate) {
      return true;
    }

    const appDate = dayjs(app.date_submitted, "YYYY/MM/DD");

    if (startDate && endDate) {
      return (
        appDate.isSame(startDate, "day") || appDate.isBefore(endDate, "day")
      );
    }

    if (startDate) {
      return appDate.isSame(startDate, "day");
    }

    if (endDate) {
      return appDate.isBefore(endDate, "day");
    }

    return true;
  });

  const styles: any = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#ffffff",
    },
    container: {
      margin: 20,
    },
  });
  const handleTabChange = (key: string) => {
    setSelectedTab(key);
  };
  const filterApplicationsByStatus = (status: string): DataType2[] => {
    return filteredData
      .filter((app) => app.status === status && isInRange(app.date_submitted))
      .map((app) => ({
        key: app.application_code,
        email: app.email,
        date_submitted: app.date_submitted,
        application_code: app.application_code,
        company_name: app.company_name,
        tax_id: app.tax_id,
        status: app.status,
        id: app.id,
      }));
  };

  const isInRange = (dateSubmitted: string) => {
    const [startDate, endDate] = dateRange.map((date: any) =>
      date ? moment(date) : null
    );

    if (!startDate && !endDate) {
      return true;
    }

    const submittedDate = moment(dateSubmitted, "YYYY/MM/DD");

    if (startDate && endDate) {
      return (
        submittedDate.isSameOrAfter(startDate, "day") &&
        submittedDate.isSameOrBefore(endDate, "day")
      );
    }

    if (startDate) {
      return submittedDate.isSameOrAfter(startDate, "day");
    }

    if (endDate) {
      return submittedDate.isSameOrBefore(endDate, "day");
    }

    return true;
  };

  const showModal = () => {
    if (selectedRowKeys[0]) setModalVisible(true);
    else console.log("Error: Επιλέξτε αιτήσεις!");
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  
  return (
    <>
      <HelmetComponent
        title={t("Αναπτυξιακή Αθήνας") || undefined}
        descrition={t("Αναπτυξιακή Αθήνας") || undefined}
      />
      <Modal
        visible={modalVisible}
        onOk={handleOk}
        onCancel={closeModal}
        cancelText="Άκυρο"
        okText="Υποβολή"
        className={classes.modal1Container}
        okButtonProps={{
          className: `${classes.submit}`,
          style: { backgroundColor: "#7DB7E3" },
        }}
        cancelButtonProps={{
          className: `${classes.submit}`,
          style: {
            backgroundColor: "transparent",
            color: "#767CA0",
            border: 0,
            boxShadow: "none",
          },
        }}
      >
        {selectedRows.length > 0 && (
          <div className={classes.modal1}>
            <h3>Επιλεγμένες αιτήσεις για αλλαγή κατάστασης</h3>
            <div>
              {selectedRows.map((row) => (
                <div key={row.id}>
                  <div className={classes.statusRow}>
                    <p>{row.application_code}</p>
                    <p>{row.company_name}</p>
                    <div>{renderStatus(row.status)}</div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <h3>Αλλαγή κατάστασης σε</h3>
              <Radio.Group
                className={classes.radioGroup}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <Radio value="1">Σε εξέλιξη</Radio>
                <Radio value="2">Εγκεκριμένη</Radio>
                <Radio value="4">Μη εγκεκριμένη</Radio>
              </Radio.Group>
            </div>
          </div>
        )}
      </Modal>
      {token != null ? (
        <div className={classes.containerAdmin}>
          <div className={classes.container}>
            <h2
              style={{ textAlign: "center", fontWeight: 600, marginBottom: 60 }}
            >
              Στατιστικά
            </h2>
            <div className={classes.dropdownContainer}>
              <Dropdown
                className={classes.dropdown}
                trigger={["click"]}
                overlay={
                  <Menu className={classes.menuExport}>
                    {/* <Menu.Item key="pdf" onClick={handlePrint}> */}
                    <Menu.Item
                      key="pdf"
                      onClick={handleExportPDF}
                      className={classes.menuItemExport}
                      style={{
                        borderBottomLeftRadius: "0 !important",
                        borderBottomRightRadius: "0 !important",
                      }}
                    >
                      PDF
                    </Menu.Item>
                    <Menu.Item
                      key="excel"
                      onClick={handleExportExcel}
                      className={classes.menuItemExport}
                      style={{
                        borderTop: "1px solid #2634891F",
                        borderTopLeftRadius: "0 !important",
                        borderTopRightRadius: "0 !important",
                      }}
                    >
                      Excel
                    </Menu.Item>
                  </Menu>
                }
              >
                <Space>
                  <img src={exportIcon} alt="export" /> Export <DownOutlined />
                </Space>
              </Dropdown>
            </div>
            <div className={classes.statContainer}>
              <Row>
                <Col
                  offset={1}
                  xl={7}
                  lg={7}
                  md={22}
                  sm={22}
                  xs={22}
                  className={classes.statCol}
                >
                  <Row>
                    <Col xl={8} className={classes.statInnerCol}>
                      {/* <h2 className={classes.stat}>{applications.length}</h2> */}
                      <h2 className={classes.stat}>{applications.length}</h2>
                      <p className={classes.statTitle}>Συνολικές Συνδρομές</p>
                    </Col>
                    <Col xl={8} className={classes.statInnerCol}>
                      <h2 className={classes.stat}>
                        {
                          applications.filter(
                            (app) => app.status === "Εγκρίθηκε"
                          ).length
                        }
                      </h2>
                      <p className={classes.statTitle}>Ενεργές Συνδρομές</p>
                    </Col>
                    <Col xl={8} className={classes.statInnerCol}>
                      <h2 className={classes.stat}>
                        {applications.length -
                          applications.filter(
                            (app) => app.status === "Εγκρίθηκε"
                          ).length}
                      </h2>
                      <p className={classes.statTitle}>Διαθέσιμες Συνδρομές</p>
                    </Col>
                  </Row>
                </Col>

                <Col
                  offset={1}
                  xl={7}
                  lg={7}
                  md={22}
                  sm={22}
                  xs={22}
                  className={classes.statCol}
                >
                  <Row>
                    <Col xl={12} className={classes.statInnerCol}>
                      <h2 className={classes.stat}>3/1/2025</h2>
                      <p className={classes.statTitle}>Έναρξη Προγράμματος</p>
                    </Col>
                    <Col xl={12} className={classes.statInnerCol}>
                      <h2 className={classes.stat}>2/1/2026</h2>
                      <p className={classes.statTitle}>Λήξη Προγράμματος</p>
                    </Col>
                  </Row>
                </Col>

                <Col
                  offset={1}
                  xl={7}
                  lg={7}
                  md={22}
                  sm={22}
                  xs={22}
                  className={classes.statCol}
                >
                  <Row>
                    <Col xl={12} className={classes.statInnerCol}>
                      <h2 className={classes.stat}>{daysPassed}</h2>
                      <p className={classes.statTitle}>
                        Μέρες που καταναλώθηκαν
                      </p>
                    </Col>
                    <Col xl={12} className={classes.statInnerCol}>
                      <h2 className={classes.stat}>{daysLeft}</h2>
                      <p className={classes.statTitle}>Μέρες που απομένουν</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <br />
            <br />

            <h2 style={{ fontWeight: 600, marginBottom: 60 }}>Αιτήσεις</h2>

            {/* <h2>Αιτήσεις - {token}</h2>
          <div className={classes.fcontainer}>
            {renderListOfApplications()}

          </div> */}

            <Tabs
              defaultActiveKey="all"
              onChange={handleTabChange}
              activeKey={selectedTab} // Use currentActiveTab as the activeKey
              className={classes.tabpane}
              tabBarStyle={{
                fontWeight: "400",
                fontSize: "20px",
                fontFamily: "Open Sans",
                color: "rgba(26, 37, 96, 0.60)",
                lineHeight: "normal",
              }}
            >
              <Tabs.TabPane
                tab={
                  <span
                    className={
                      selectedTab === "all" ? classes.activetab : classes.tabTab
                    }
                  >
                    Όλες
                  </span>
                }
                key="all"
              >
                <button className={classes.addButton} style={{marginTop: '1.4rem'}}
                onClick={() => navigate('/add_application')}
                ><PlusOutlined style={{marginRight: '1rem'}}/> Προσθήκη Αίτησης</button>

                <div className={classes.innerRight}>
                  <div className={classes.customSearchContainer}>
                    <Input
                      className={classes.search}
                      allowClear
                      placeholder="Αναζήτηση εδώ"
                      onChange={handleSearchInputChange}
                      style={{ width: 200, color: "yellow !important" }}
                    />
                    <img
                      src={search_fill}
                      alt="Search Icon"
                      className={classes.searchIcon}
                    />
                  </div>

                  <RangePicker
                    style={{ marginBottom: 25 }}
                    className={classes.rangePicker}
                    // presets={rangePresets}
                    onChange={onRangeChange}
                    value={dateRange}
                    locale={locale}
                  />
                  <br />
                  <br />
                </div>
                {/* <Table
                  bordered
                  columns={columns2}
                  dataSource={filteredData.map((app) => ({
                    ...app,
                    key: app.application_code, // Use application_code as the key
                  }))}
                /> */}
                <Table
                  bordered
                  id="tableToExportAll"
                  columns={columns2}
                  pagination={{ pageSize: 3 }}
                  className={classes.tableData }
                  locale={customNoDataLocale}
                  scroll={{ x: "max-content", y: "calc(100vh - 400px)" }}
                  dataSource={applications
                    .filter((app) => {
                      const dateSubmitted = dayjs(
                        app.date_submitted,
                        "YYYY/MM/DD"
                      );
                      return (
                        (!dateRange[0] ||
                          dateSubmitted.isSame(dayjs(dateRange[0]), "day") ||
                          dateSubmitted.isAfter(dayjs(dateRange[0]), "day")) &&
                        (!dateRange[1] ||
                          dateSubmitted.isSame(dayjs(dateRange[1]), "day") ||
                          dateSubmitted.isBefore(dayjs(dateRange[1]), "day"))
                      );
                    })
                    .map((app) => ({
                      ...app,
                      key: app.application_code, // Use application_code as the key
                    }))}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span
                    className={
                      selectedTab === "Σε εξέλιξη"
                        ? classes.activetab
                        : classes.tabTab
                    }
                  >
                    Σε εξέλιξη
                  </span>
                }
                key="inProgress"
              >
                <button className={classes.addButton} 
                onClick={() => navigate('/add_application')}
                ><PlusOutlined style={{marginRight: '1rem'}}/> Προσθήκη Αίτησης</button>
                <img
                  src={editButton}
                  className={classes.editButton}
                  alt="edit_button"
                  onClick={() => showModal()}
                />
                <div className={classes.innerRight}>
                  <div className={classes.customSearchContainer}>
                    <Input
                      className={classes.search}
                      allowClear
                      placeholder="Αναζήτηση εδώ"
                      onChange={handleSearchInputChange}
                      style={{ width: 200 }}
                    />
                    <img
                      src={search_fill}
                      alt="Search Icon"
                      className={classes.searchIcon}
                    />
                  </div>
                  <RangePicker
                    style={{ marginBottom: 25 }}
                    className={classes.rangePicker}
                    // presets={rangePresets}
                    onChange={onRangeChange}
                    value={dateRange}
                    locale={locale}
                  />
                  <br />
                  <br />
                </div>
                {/* <Table
                  bordered
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection2,
                  }}
                  columns={columns2}
                  dataSource={applications
                    .filter((app) => {
                      const dateSubmitted = dayjs(app.date_submitted, 'YYYY/MM/DD');
                      return (
                        (!dateRange[0] || dateSubmitted.isSame(dayjs(dateRange[0]), 'day') || dateSubmitted.isAfter(dayjs(dateRange[0]), 'day')) &&
                        (!dateRange[1] || dateSubmitted.isSame(dayjs(dateRange[1]), 'day') || dateSubmitted.isBefore(dayjs(dateRange[1]), 'day'))
                      );
                    })
                    .map((app) => ({
                      ...app,
                      key: app.application_code, // Use application_code as the key
                    }))}
                /> */}
                <Table
                  id="tableToExport1"
                  bordered
                  ref={tableRef}
                  className={classes.tableData}
                  locale={customNoDataLocale}
                  scroll={{ x: "max-content" }}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection2,
                    columnWidth: 0,
                  }}
                  columns={columns2}
                  dataSource={applications
                    .filter((app) => {
                      const dateSubmitted = dayjs(
                        app.date_submitted,
                        "YYYY/MM/DD"
                      );
                      return (
                        (!dateRange[0] ||
                          dateSubmitted.isSame(dayjs(dateRange[0]), "day") ||
                          dateSubmitted.isAfter(dayjs(dateRange[0]), "day")) &&
                        (!dateRange[1] ||
                          dateSubmitted.isSame(dayjs(dateRange[1]), "day") ||
                          dateSubmitted.isBefore(dayjs(dateRange[1]), "day")) &&
                        app.status == "Σε εξέλιξη"
                      );
                    })
                    .map((app) => ({
                      ...app,
                      key: app.application_code, // Use application_code as the key
                    }))}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span
                    className={
                      selectedTab === "Εγκεκριμένες"
                        ? classes.activetab
                        : classes.tabTab
                    }
                  >
                    Εγκεκριμένες
                  </span>
                }
                key="approved"
              >
                <button className={classes.addButton} 
                onClick={() => navigate('/add_application')}
                ><PlusOutlined style={{marginRight: '1rem'}}/> Προσθήκη Αίτησης</button>

                <img
                  src={editButton}
                  className={classes.editButton}
                  alt="edit_button"
                  onClick={() => showModal()}
                />
                <div className={classes.innerRight}>
                  <div className={classes.customSearchContainer}>
                    <Input
                      className={classes.search}
                      allowClear
                      placeholder="Αναζήτηση εδώ"
                      onChange={handleSearchInputChange}
                      style={{ width: 200 }}
                    />
                    <img
                      src={search_fill}
                      alt="Search Icon"
                      className={classes.searchIcon}
                    />
                  </div>
                  <RangePicker
                    style={{ marginBottom: 25 }}
                    className={classes.rangePicker}
                    // presets={rangePresets}
                    onChange={onRangeChange}
                    value={dateRange}
                    locale={locale}
                  />
                  <br />
                  <br />
                </div>
                <Table
                  id="tableToExport2"
                  bordered
                  ref={tableRef}
                  className={classes.tableData}
                  locale={customNoDataLocale}
                  scroll={{ x: "max-content" }}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection2,
                    columnWidth: 0,
                  }}
                  columns={columns2}
                  dataSource={applications
                    .filter((app) => {
                      const dateSubmitted = dayjs(
                        app.date_submitted,
                        "YYYY/MM/DD"
                      );
                      return (
                        (!dateRange[0] ||
                          dateSubmitted.isSame(dayjs(dateRange[0]), "day") ||
                          dateSubmitted.isAfter(dayjs(dateRange[0]), "day")) &&
                        (!dateRange[1] ||
                          dateSubmitted.isSame(dayjs(dateRange[1]), "day") ||
                          dateSubmitted.isBefore(dayjs(dateRange[1]), "day")) &&
                        app.status == "Εγκρίθηκε"
                      );
                    })
                    .map((app) => ({
                      ...app,
                      key: app.application_code, // Use application_code as the key
                    }))}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span
                    className={
                      selectedTab === "Μη εγκεκριμένες"
                        ? classes.activetab
                        : classes.tabTab
                    }
                  >
                    Μη εγκεκριμένες
                  </span>
                }
                key="notApproved"
              >
                <button className={classes.addButton} 
                onClick={() => navigate('/add_application')}
                ><PlusOutlined style={{marginRight: '1rem'}}/> Προσθήκη Αίτησης</button>

                <img
                  src={editButton}
                  className={classes.editButton}
                  alt="edit_button"
                  onClick={() => showModal()}
                />
                <div className={classes.innerRight}>
                  <div className={classes.customSearchContainer}>
                    <Input
                      className={classes.search}
                      allowClear
                      placeholder="Αναζήτηση εδώ"
                      onChange={handleSearchInputChange}
                      style={{ width: 200 }}
                    />
                    <img
                      src={search_fill}
                      alt="Search Icon"
                      className={classes.searchIcon}
                    />
                  </div>
                  <RangePicker
                    style={{ marginBottom: 25 }}
                    className={classes.rangePicker}
                    // presets={rangePresets}
                    onChange={onRangeChange}
                    value={dateRange}
                    locale={locale}
                  />
                  <br />
                  <br />
                </div>
                <Table
                  id="tableToExport4"
                  bordered
                  ref={tableRef}
                  className={classes.tableData}
                  scroll={{ x: "max-content" }}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection2,
                    columnWidth: 0,
                  }}
                  columns={columns2}
                  dataSource={applications
                    .filter((app) => {
                      const dateSubmitted = dayjs(
                        app.date_submitted,
                        "YYYY/MM/DD"
                      );
                      return (
                        (!dateRange[0] ||
                          dateSubmitted.isSame(dayjs(dateRange[0]), "day") ||
                          dateSubmitted.isAfter(dayjs(dateRange[0]), "day")) &&
                        (!dateRange[1] ||
                          dateSubmitted.isSame(dayjs(dateRange[1]), "day") ||
                          dateSubmitted.isBefore(dayjs(dateRange[1]), "day")) &&
                        app.status == "Απορρίφθηκε"
                      );
                    })
                    .map((app) => ({
                      ...app,
                      key: app.application_code, // Use application_code as the key
                    }))}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div></div>
        </div>
      ) : (
        ((window as Window).location = "./loginuser")
      )}
    </>
  );
}

export default Dashboard;
