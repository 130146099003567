import React, { useState, useEffect, useContext, useRef } from "react";
import {
  AimOutlined,
  CalendarOutlined,
  AlertOutlined,
  NodeIndexOutlined,
  SearchOutlined,
  MenuOutlined,
  DownOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Select } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classes from "./Navbar.module.css";
import {
  CloseOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { SidebarData } from "./SidebarData";
// import twitter from '../../img/twitter.png'
// import facebook from '../../img/facebook.png'
import login from "../../img/login.png";
import menu from "../../img/menu.png";
import account from "../../img/account.png";
import SubMenu from "./SubMenu";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import i18n from "i18next";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import LangSwitch from "../LangSwitch/LangSwitch";
import authService from "../../services/auth.service";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

interface User {
  email: string;
  // Add other properties of the user if available
}

const Navbar = () => {
  // const activeClass = (path: string, link: string) => {
  //   if (window.location === link) {
  //     return true;
  //   }
  //   return false;
  // };
  const [sidebar, setSidebar] = useState(false);
  const [submenu, setSubmenu] = useState(false);
  const [size, setSize] = useState<SizeType>("large");
  const [user, setUser] = useState<User | null>(null);
  const token = window.localStorage.getItem("token");
  const navbarRef = useRef<HTMLDivElement>(null);
  const showSidebar = (tab: any) => {
    console.log(tab.title);

    if (tab.toggle) setSidebar(!sidebar);
  };

  const [subNav, setSubNav] = useState(false);
  const showSubNav = () => setSubNav(!subNav);

  const [basicOpen, setBasicOpen] = useState(true);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const { setIsLoggedOut } = useContext(AuthContext);
  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const [logedInUser, setLogedInUser] = useState({
    email: '',
    firstname: '',
    id: '',
    lastname: '',
    phoneNubmer: ''
  })

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_NAME}/api/Auth/GetAdminUsers`
        );

        const username = window.localStorage.getItem('username')

        const userProfile = response.data.filter((user: any) => user.email === username)

        setLogedInUser(userProfile[0])
        
        console.log(userProfile)
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  const getUserInitials = () => {
    if(logedInUser?.email) {
      if(logedInUser?.email !== 'admin@eata-dotsoft.gr') {
        return "" + logedInUser.firstname[0] + logedInUser.lastname[0]
      } else return "AD"
    } else return ""
  }

  const handleLogout = () => {
    // Call the logout service or function here
    // authService.logout();

    // Set the isLoggedOut state to true
    setIsLoggedOut(true);

    localStorage.clear()
    window.location.reload();
  };

  const handleChange = (value: string) => {
    i18n.changeLanguage(value);
  };

  const menuDropdown = (
    <Menu style={{ padding: 0 }}>
      <Menu.Item key="login" style={{ padding: "2px 0" }}>
        <Link to="/profile" state={{ page: "profile" }}>
          <Button
            className={classes.login}
            type="primary"
            icon={<img src={login} alt="login icon" />}
            size={size}
          >
            {t("Προφίλ")}
          </Button>
        </Link>
      </Menu.Item>
      <Menu.Item key="logout" style={{ padding: "2px 0" }}>
        <Button
          className={classes.login}
          type="primary"
          icon={<img src={login} alt="login icon" />}
          size={size}
          onClick={handleLogout}
        >
          {t("Έξοδος")}
        </Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  const handleOutsideClick = (event: any) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setSidebar(false);
    }
  };
  const handleProfile = () => {
    authService.navigateProfile();
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div style={{ height: "100%", alignContent: "center" }}>
      {window.localStorage.getItem("userType") !== "admin" ? (
        <>
          {screenWidth > 1180 && (
            <ul className={[classes.list, classes.items].join(" ")}>
              {SidebarData.map((item: any, index: any) => {
                return (
                  <NavLink
                    key={item.id}
                    to={item.path}
                    style={({ isActive }) => ({
                      fontWeight: isActive ? 900 : "normal",
                    })}
                    state={{ page: item.state }}
                    onClick={toggleNav}
                  >
                    <p style={{marginTop: '1.5rem'}}>{t(item.title)}{" "}</p>
                    {item.iconClosed ? (
                      <CaretDownOutlined
                        onClick={() => {
                          setSubmenu(!submenu);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </NavLink>
                );
              })}
            </ul>
          )}
        </>
      ) : (
        <>
          {screenWidth > 1180 && (
            <ul className={[classes.list, classes.itemsAdmin].join(" ")}>
              {SidebarData.map((item: any, index: any) => {
                return (
                  <NavLink
                    key={item.id}
                    to={item.path}
                    style={({ isActive }) => ({
                      fontWeight: isActive ? 900 : "normal",
                      // background: isActive ? '#7600dc' : '#f0f0f0',
                    })}
                    state={{ page: item.state }}
                    onClick={toggleNav}
                  >
                    {t(item.title)}{" "}
                    {item.title === 'Προφίλ' && (
                      <div className={classes.userInitials}>{getUserInitials()}</div>
                    )}
                    {item.iconClosed ? (
                      <CaretDownOutlined
                        onClick={() => {
                          setSubmenu(!submenu);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </NavLink>
                );
              })}
            </ul>
          )}
        </>
      )}
      <button
        onClick={() => {
          setSidebar(!sidebar);
        }}
        className={classes.btn}
      >
        <img src={menu} alt="menu" />
      </button>

      <nav
        ref={navRef}
        className={sidebar ? classes.navMenuActive : classes.navMenu}
      >
        <ul className={classes.navMenuItems}>
          <li className={classes.navbarToggle}>
            <Link to="#" className={classes.menuBars}>
              <CloseOutlined
                className={classes.closeIcon}
                onClick={() => {
                  setSidebar(!sidebar);
                }}
              />
            </Link>
          </li>
          {SidebarData.map((item: any, index: any) => {
            return (
              <li
                key={index}
                className={classes.navText}
                onClick={() => {
                  showSidebar(item);
                }}
              >
                <SubMenu
                  item={item}
                  key={index}
                  onClick={() => {
                    showSidebar(item);
                  }}
                />
              </li>
              // {Submenu.map((item: any, index: any) => {})}
            );
          })}

          <li className={classes.navText}>
            {/* <Link to="/loginuser" state={{ page: 'login' }} onClick={() => { setSidebar(!sidebar) }}> */}
            {!user?.email && token == null ? (
              <Link
                to="/loginuser"
                state={{ page: "login" }}
                onClick={() => window.localStorage.clear()}
              >
                <Button
                  className={classes.login}
                  type="primary"
                  icon={<img src={login} alt="login icon" />}
                  size={size}
                  onClick={() => {
                    setSidebar(!sidebar);
                    window.localStorage.clear();
                  }}
                >
                  {t("Είσοδος")}
                </Button>
              </Link>
            ) : (
              // <Dropdown overlay={menuDropdown} trigger={["hover"]}>
              //   <Button
              //     className={classes.login}
              //     style={{
              //       height: "64%",
              //       backgroundColor: "transparent",
              //       boxShadow: "none",
              //     }}
              //     type="primary"
              //     size={size}
              //   >
              //     {/* You can set the default label for the dropdown button here */}
              //     <img src={account} alt="Account" />
              //   </Button>
              // </Dropdown>
              <div className={classes.loggedContainer}>
                <Button
                  className={classes.login}
                  type="primary"
                  size={size}
                  onClick={handleProfile}
                >
                  {t("Προφίλ")}
                </Button>
                <Button
                  className={classes.login}
                  type="primary"
                  size={size}
                  onClick={handleLogout}
                >
                  {t("Αποσύνδεση")}
                </Button>
              </div>
            )}
          </li>

          {/* <div className={classes.socialSidebar}>
            <Select
              className={classes.selectLang}
              defaultValue="gr"
              size="large"
              onSelect={handleChange}
              options={[
                {
                  key: 1,
                  value: "en",
                  label: "EN",
                },
                {
                  key: 2,
                  value: "gr",
                  label: "ΕΛ",
                },
              ]}
            ></Select>
          </div> */}
        </ul>
      </nav>
    </div>
  );
};
export default Navbar;
