import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import classes from "./EditApplication.module.css"
import { Button, Col, Row, Form, Input, Tooltip, Radio, Dropdown, Menu, Upload } from 'antd';
import { DownOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react";
import success from '../../img/success.png'
import fail from '../../img/fail.png'
import mail_success from '../../img/mail_success.png'
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import danger from '../../img/danger.png';
import done from '../../img/done.png';
import del from '../../img/delete.png';
import edit_transparent from '../../img/edit_transparent.png';
import mail from '../../img/mail.png';
import lock from '../../img/lock.png';
import edit_filled from '../../img/edit_filled.png';
import saveIcon from '../../img/Save.png'
import exclamation from '../../img/exclamation.png'
import download from "../../img/download.png";
import upload from "../../img/add_to_drive.png";
import axios from "axios";

const LockableTextArea = ({ locked, value, ...rest }: { locked: boolean; value?: string } & React.ComponentProps<typeof Input.TextArea>) => {
  return (
    <div className={classes.lockableTextAreaContainer}>
      <Input.TextArea
        className={`${classes.mIdInput} ${locked ? classes.locked : ''}`}
        {...rest}
        value={value}
      />
      {locked && (
        <div className={classes.lockIconContainer}>
          <Tooltip title="Not Editable">
            <img src={lock} alt="lock" style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.25)', marginLeft: '8px' }} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const LockableInput = ({ locked, value, ...rest }: { locked: boolean; value?: string } & React.ComponentProps<typeof Input.TextArea>) => {
  return (
    <div className={classes.lockableInputContainer}>
      <Input.TextArea
        className={`${classes.mIdInput} ${locked ? classes.locked : ''}`}
        {...rest}
        value={value}
        autoSize={{ minRows: 1, maxRows: 6 }} // Adjust minRows and maxRows as needed
      />
      {locked && (
        <div className={classes.lockIconContainer}>
          <Tooltip title="Not Editable">
            <img src={lock} alt="lock" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

// const columns2: ColumnsType<DataType2> = [
const columns2 = [
  {
    title: 'Ημερομηνία Υποβολής',
    dataIndex: 'date_submitted',
    key: 'date_submitted',
  },
  {
    title: 'Αριθμός Αίτησης',
    dataIndex: 'application_code',
    key: 'application_code',
  },
  {
    title: 'Κατάσταση Αίτησης',
    dataIndex: 'status',
    key: 'status',
  }
];

const Profile = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [deletion, setDeletion] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [status, setStatus] = useState<number | null>(null);
  const { isOpenEdit, isOpenEditSuccess, toggleEdit, toggleEditSuccess } = useModal();
  const { isOpenUpload, isOpenUploadSuccess, toggleUpload, toggleUploadSuccess, uploadFileName } = useModal();
  const { isOpenDeletion, isOpenDeletionSuccess, toggleDeletion, toggleDeletionSuccess } = useModal();
  const [placeholder, setPlaceholder] = useState<string>('');
  const [statusEmail, setStatusEmail] = useState<boolean>(false);
  const [zipcode, setZipcode] = useState<string>("");
  const axiosConfig = {
    headers: {
      'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
      'Content-Type': 'application/json'
    }
  };
  const [activities, setActivities] = useState<Array<{
    recid: number;
    value: string;
    enabled: boolean
  }>>([{
    recid: -1,
    value: '-',
    enabled: false
  }]);

  const [selectedActivity, setSelectedActivity] = useState<number | null>(null);
  const [application, setApplication] = useState<Array<{
    id: string;
    application_code: string;
    company_name: string;
    firstname: string;
    lastname: string;
    fathername: string;
    tax_id: string;
    activity: string;
    address: string;
    address_number: string;
    date_expiration: string;
    location: string;
    tax_office: string;
    region: string;
    phone1: string;
    phone2: string;
    email: string;
    file1: string;
    file2: string;
    file3: string;
    file4: string;
    file5: string;
    emailFiles: string;
    microsoft_id: string;
    microsoft_username: string;
    comments: string;
    status_id: number;
    application_id: number;
    zipcode: string;
    sorefsis_amount: string;
    enabled: boolean;
  }>>([{
    id: '',
    application_code: '-',
    firstname: '-',
    lastname: '-',
    fathername: '-',
    company_name: '-',
    tax_id: '-',
    activity: '-',
    address: '-',
    address_number: '',
    date_expiration: '-',
    location: '-',
    tax_office: '-',
    region: '-',
    phone1: '-',
    phone2: '-',
    email: '-',
    file1: '-',
    file2: '-',
    file3: '-',
    file4: '-',
    file5: '-',
    emailFiles: '-',
    microsoft_id: '-',
    microsoft_username: '-',
    comments: '-',
    status_id: -1,
    application_id: -1,
    zipcode: '-',
    sorefsis_amount: '',
    enabled: false
  }]);
  const [formValues, setFormValues] = useState({
    application_code: "",
    date_submitted: "",
    company_name: "",
    firstname: "",
    lastname: "",
    address: "",
    address_number: "",
    username: window.localStorage.getItem("username"),
    password: "",
    tax_id: '',
    activity: '',
    zipcode: '',
    location: '',
    tax_office: '',
    region: '',
    phone1: "",
    phone2: "",
    email: "",
    file1: "",
    file2: "",
    file3: "",
    file4: "",
    file5: "",
    emailFiles: "",
    microsoft_id: "",
    microsoft_username: "",
    sorefsis_amount: ''
  });
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const [editMode, setEditMode] = useState(false);
  const { id } = useParams();
  const [areaStatus, setAreaStatus] = useState<boolean | null>(null);
  
  const onFinish = (values: any) => {
    console.log(values);
    submitUpdate()
    toggleEditMode()
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleUploadSelection = ({ file, onSuccess }: any) => {
    if (file instanceof File) {
      setUploadFileData(file); // Update the file data in your component state
      onSuccess('Upload successful', file); // Indicate upload success
    }
  };

  const handleEdit = async () => {
    axios
      .put(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/UpdateStatus`, {
        id: application[0]?.id,
        status_id: selectedStatus,
      }, axiosConfig)
      .then((response) => {
        // Handle the response if needed
        console.log("Content sent successfully:", response);
        toggleEdit();
        setEdit(!edit);
        toggleEditSuccess();
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  }

  const handleButtonClick = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Delete/${application[0].id}`, axiosConfig);
      console.log(`Application with ID ${application[0].id} has been deleted.`);
      toggleDeletion();
      setDeletion(!deletion);
    } catch (error) {
      console.error("Error while deleting the application:", error);
    }
    toggleDeletionSuccess();
  }

  const [editableContent, setEditableContent] = useState(placeholder);
  const [emailBody, setEmailBody] = useState('');
  const sentEmail = () => {
    // Get the updated content from the state variable
    const updatedContent = emailBody.replace(/\n/g, '<br>');
    
    // Convert the HTML content to a JSON string
    console.log(updatedContent);

    // Send the updated content to the server using Axios PUT request
    axios
      .put(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/UpdateStatus`, {
        id: application[0]?.id, // Replace with the actual ID if available
        status_id: application[0]?.status_id, // Replace with the actual status ID
        email: application[0]?.email, // Replace with the actual email
        body: updatedContent,
      }, axiosConfig)
      .then((response) => {
        // Handle the response if needed
        setStatusEmail(true);
        console.log("Content sent successfully:", response);
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error updating status:", error);
      });
  };

  const [fileData, setFileData] = useState<{ fileUrl: string; filename: string } | null>(null); // Explicitly define the type
  const [uploadFileData, setUploadFileData] = useState<File | null>(null);

  // {
  //   "id": "ad96cbdd-cf5c-423d-b579-18729312c5b3",
  //       "lastname": null,
  //       "fathername": null,
  //       "company_name": null,
  //       "sorefsis_amount": "5555"
  //   }

  // {"id":"ad96cbdd-cf5c-423d-b579-18729312c5b3",
  // "lastname":null,
  // "fathername":null,
  // "company_name":null,
  // "sorefsis_amount":"12223242.44"}


  const submitUpdate = () => {
    console.log("submitUpdate");

    axios
      .put(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/UpdateAdmin`, {
        id: application[0]?.id?.toString(),
        application_code: application[0]?.application_code?.toString(),
        date_approved: null,
        date_submitted: formValues.date_submitted?.toString() || null,
        date_expiration: application[0]?.date_expiration?.toString() || null,
        date_stopped: null,
        firstname: application[0]?.firstname?.toString() || null,
        lastname: application[0]?.lastname?.toString() || null,
        fathername: application[0]?.fathername?.toString() || null,
        company_name: formValues.company_name?.toString() || null,
        phone1: formValues?.phone1?.toString() || null,
        phone2: formValues?.phone2?.toString() || null,
        address: formValues?.address?.toString() || null,
        address_number: formValues?.address_number?.toString() || null,
        zipcode: formValues?.zipcode?.toString() || null,
        location: formValues?.location?.toString() || null,
        tax_office: formValues?.tax_office?.toString() || null,
        region: formValues?.region?.toString() || null,
        comments: application[0]?.comments?.toString() || null,
        microsoft_id: formValues?.microsoft_id?.toString() || null,
        microsoft_username: formValues?.microsoft_username?.toString() || null,
        email: formValues?.email?.toString() || null,
        sorefsis_amount: formValues.sorefsis_amount.toString(),
        activity_id: Number(selectedActivity)
      }, axiosConfig)
      .then((response) => {
        console.log("Content sent successfully:", response);
        toggleEditSuccess();
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error on updating the application:", error);
      });
  }

  const uploadFile = async (fileorder: string) => {
    if (!uploadFileData) {
      console.error('No file selected.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', uploadFileData);
      formData.append('Id', application[0].id);
      formData.append('fileorder', fileorder);

      const response = await axios.put(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/UpdateFile`, formData, {
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('File uploaded successfully:', response);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setUploadFileData(selectedFile);
    }
  };

  const handleEmailFileChange = (info: any, setter: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (info.fileList.length > 0) {
      const nativeFile = info.fileList[info.fileList.length - 1].originFileObj as File;
      setter(nativeFile);
    } else {
      setter(null);
    }
  };

  const downloadFile = async (file: string) => {
    console.log(file);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Download?id=${application[0].id}&fileorder=${file}`,
        {
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Extract the filename from the Content-Disposition header
      const contentDispositionHeader = response.headers['content-disposition'];
      const encodedFilename = contentDispositionHeader?.split('filename*=UTF-8\'\'')[1];
      const decodedFilename = decodeURIComponent(encodedFilename || '');

      const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
      const fileUrl = URL.createObjectURL(fileBlob);

      await setFileData({ fileUrl, filename: decodedFilename || application[0].file1 });
      downloadFileAutomatically()
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const downloadFileAutomatically = () => {
    if (fileData) {
      const link = document.createElement('a');
      link.href = fileData.fileUrl;
      link.download = fileData.filename;
      link.click();
    }
  };
  const fetchApplication = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Search?app_form_code=${id}`);

      setApplication(response.data);
      await setSelectedActivity(response.data[0].activity_id)
      const nullToEmpty = (value: any) => (value === (null || "null") ? "" : value);
      await setFormValues(prevFormValues => ({
        ...prevFormValues,
        application_id: response.data[0].application_id || "",
        date_submitted: response.data[0].date_submitted || "",
        firstname: response.data[0].firstname || "",
        company_name: nullToEmpty(response.data[0].company_name || ""),
        phoneNumber: nullToEmpty(response.data[0].phoneNumber || ""),
        tax_id: response.data[0].tax_id || "",
        activity: nullToEmpty(response.data[0].activity || ""),
        address: response.data[0].address || "",
        address_number: response.data[0].address_number || "",
        zipcode: nullToEmpty(response.data[0].zipcode || ""),
        location: response.data[0].location || "",
        region: nullToEmpty(response.data[0].region || ""),
        phone1: nullToEmpty(response.data[0].phone1 || ""),
        phone2: nullToEmpty(response.data[0].phone2 || ""),
        email: response.data[0].email || "",
        file1: nullToEmpty(response.data[0].file1 || ""),
        file2: nullToEmpty(response.data[0].file2 || ""),
        microsoft_id: nullToEmpty(response.data[0].microsoft_id || ""),
        microsoft_username: nullToEmpty(response.data[0].microsoft_username || ""),
        sorefsis_amount: nullToEmpty(response.data[0].sorefsis_amount || ""),
      }));

      if (response.data[0]?.status_id && response.data[0]?.status_id > 0) {
        setStatus(response.data[0].status_id);
        if (response.data[0].status_id === 2) {
          await setPlaceholder(`Αγαπητέ/ή ${response.data[0].company_name},<div><br><div>Θα θέλαμε να σας ενημερώσουμε ότι η αίτησή σας για το πρόγραμμα «Ψηφιακός Μετασχηματισμός Επιχειρήσεων» πληροί τα κριτήρια που έχουν οριστεί για το πρόγραμμα.<div><br></div><div>Θα λάβετε επιπλέον πληροφορίες σχετικά με το επόμενο βήμα της διαδικασίας αίτησης μέσω email στο επόμενο διάστημα.</div><div><br></div><div>Σας ευχαριστούμε για το ενδιαφέρον σας για το πρόγραμμά μας.</div><div><br></div><div>Με εκτίμηση,</div></div></div>`);
        } else if (response.data[0].status_id === 4) {
          await setPlaceholder(`Αγαπητέ/ή ${response.data[0].company_name},<div><br><div>Δυστυχώς, πρέπει να σας ενημερώσουμε ότι δεν πληροίτε τα απαραίτητα κριτήρια για τη συμμετοχή στο πρόγραμμα «Ψηφιακός Μετασχηματισμός Επιχειρήσεων».<div><br></div><div>Εάν θέλετε να μάθετε περισσότερες πληροφορίες σχετικά με τα κριτήρια της απόρριψης, παρακαλούμε να επικοινωνήσετε μαζί μας και θα σας παρέχουμε όσες πληροφορίες χρειάζεστε.</div><div><br></div><div>Σας ευχαριστούμε για το ενδιαφέρον σας για το πρόγραμμά μας.</div><div><br></div><div>Με εκτίμηση,</div></div></div>`);
        }
      }
    } catch (error) {
      console.error('Error fetching application:', error);
    }

    const setEditableContentAsync = async () => {
      try {
        // Simulate an async API call or any other async operation
        await new Promise((resolve) => setTimeout(resolve, 1000));

        setEditableContent(placeholder);
      } catch (error) {
        console.error("Error setting editable content:", error);
      }
    };

    // Call the async function
    setEditableContentAsync();
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_NAME}/api/Activity/GetAll`);
      setActivities(response.data);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };
  // const handleOk = async () => {
  //   if (!selectedStatus) {
  //     // If no status is selected, do not proceed with the Axios call
  //     console.log('Please select a status.');
  //     return;
  //   }

  //   try {
  //     // Array to store all the Axios promises
  //     const updatePromises: Promise<any>[] = [];

  //     // Loop through each selected row and create a separate Axios request for each one

  //     const promise = axios.put<any>(
  //       `https://map.social-network.gr/eatams/api/ApplicationForm/UpdateStatus?id=${application.id}&status_id=${selectedStatus}`
  //     );
  //     updatePromises.push(promise);

  //     // Execute all Axios requests concurrently using Promise.all
  //     const responses = await Promise.all(updatePromises);

  //     closeModal();
  //   } catch (error) {
  //     console.error('Error updating status:', error);
  //   }
  // };

  const handleMenuClick = (e: any) => {
    const selectedValue = activities.find(activity => activity.recid.toString() === e.key)?.recid;

    setSelectedActivity(parseInt(e.key, 10));

    if (selectedValue) {
      console.log('Selected option value:', selectedValue);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={selectedActivity ? [selectedActivity.toString()] : []} className={classes.menuActivitiesContainer}>
      {activities.map((activity) => (
        <Menu.Item key={activity.recid} disabled={!activity.enabled} className={classes.itemActivity}>
          {activity.value}
        </Menu.Item>
      ))}
    </Menu>
  );

  const getZipcode = async (application_code: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_NAME}/api/Auth/SearchAddress/${application_code}`);
      setZipcode(response.data.zipcode);
    } catch (error: any) {
      console.error('Error:', error.message);
    }
  }

  const checkAreaStatus = async () => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/SearchAddress/`, {
        params: {
          address: application[0].address,
          number: application[0].address_number,
          zipcode: application[0].zipcode
        }
      });

      // Process the response data here
      console.log('Response:', response.data);
      setAreaStatus(response.data);
      // Return or use the response data as needed

    } catch (error: any) {
      console.error('Error:', error.message);
    }
  }

  useEffect(() => {
    fetchActivities();
    fetchApplication();
    getZipcode(application[0].application_code);
    console.log("Application: ", application[0]);


    if (application[0].status_id !== -1) {
      setSelectedStatus(application[0].status_id);
    }
    checkAreaStatus();



  }, [status]);

  console.log("this", application)
  return (
    <>
      <HelmetComponent title={t('Αναπτυξιακή Αθήνας') || undefined} descrition={t('Αναπτυξιακή Αθήνας') || undefined} />
      <Modal isOpen={isOpenUpload} toggle={() => {
        toggleUpload(uploadFileName);
        setUploadFileData(null);
      }} step={1}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={() => {
            toggleUpload(uploadFileName);
            setUploadFileData(null);
          }}>x</button>
        </div>
        <div className={classes.message}>
          <h2>Επιλογή αρχείου ({uploadFileName}):</h2>

          <Upload
            className={classes.upload}
            customRequest={handleUploadSelection}
          >
            <div>
              <Button icon={<PlusOutlined />} />

            </div>
          </Upload>
          <div className={classes.formItemContainer}>
            <Button type="primary" className={classes.submit} style={{ backgroundColor: '#263489' }} htmlType="submit"
              onClick={() => {
                toggleUpload(uploadFileName);
                setUploadFileData(null);
              }}>
              Άκυρο
            </Button>
            <Button type="primary" className={classes.submit} style={{ backgroundColor: '#7BB6E1', width: 146 }} htmlType="submit" onClick={() => {
              uploadFile(uploadFileName);
              toggleUpload(uploadFileName); // Open the modal and pass the file name
            }}>
              Ολοκλήρωση
            </Button>
          </div>
        </div>
      </Modal >
      <Modal isOpen={isOpenDeletion} toggle={toggleDeletion} step={1}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleDeletion}>x</button></div>
        <div className={classes.message}>
          <h2>Είστε σίγουροι ότι θέλετε να διαγράψετε την αίτηση;</h2>

          <div className={classes.formItemContainer}>

            <Button type="primary" className={classes.submit} style={{ backgroundColor: '#263489' }} htmlType="submit" onClick={() => toggleDeletion()}>
              Άκυρο
            </Button>
            <Button type="primary" className={classes.submit} style={{ backgroundColor: '#ED5350' }} htmlType="submit" onClick={() => handleButtonClick()}>
              Ναι
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isOpenDeletionSuccess} toggle={toggleDeletionSuccess} step={2}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={toggleDeletionSuccess}>x</button>
        </div>
        <div className={classes.dangerContainer}>
          <img src={done} alt="message" />
        </div>
        <div className={classes.message}>
          <p>Η αίτηση έχει διαγραφεί επιτυχώς!</p>
          <button  className={classes.okBtn} style={{ backgroundColor: '#263489' }} onClick={() => navigate("/dashboard")}>Εντάξει</button>
        </div>
      </Modal>
      <Modal isOpen={isOpenEdit} toggle={toggleEdit} step={1}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleEdit}>x</button></div>
        <div className={classes.message}>
          <h2>Eπιλέξτε την κατάσταση της αίτησης</h2>
          <Radio.Group value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)} className={classes.radioGroup}>
            {application[0].status_id !== 1 ? <Radio value="1">Σε εξέλιξη</Radio> : ''}
            {application[0].status_id !== 2 ? <Radio value="2">Εγκεκριμένη</Radio> : ''}
            {application[0].status_id !== 4 ? <Radio value="4">Μη εγκεκριμένη</Radio> : ''}
          </Radio.Group>
          <div className={classes.formItemContainer}>

            <Button type="primary" className={classes.resetBtn} style={{ backgroundColor: 'transparent' }} htmlType="submit" onClick={() => setSelectedStatus(null)} >
              Καθαρισμός
            </Button>
            <Button type="primary" className={classes.submit} style={{ backgroundColor: '#7DB7E3' }} htmlType="submit" onClick={() => handleEdit()}>
              Υποβολή
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isOpenEditSuccess} toggle={toggleEditSuccess} step={2}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={toggleEditSuccess}>x</button>
        </div>
        <div className={classes.dangerContainer}>
          <img src={done} alt="message" />
        </div>
        <div className={classes.message}>
          <p>Η κατάσταση της αίτησης έχει ενημερωθεί επιτυχώς!</p>
          <button className={classes.submit} style={{ backgroundColor: '#263489' }} onClick={() => navigate("/dashboard")}>Εντάξει</button>
        </div>
      </Modal>
      <div className={classes.container}>

        <div className={classes.fcontainer}>

          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={formValues}
          >
            <Row>
              <Col xs={24} sm={24} md={23} lg={23} xl={23}>
                <Row>
                  <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                    <br />
                  </Col>
                  <Col xs={24} sm={14} md={15} lg={14} xl={16}>
                    <div className={classes.titleContainer}>
                      <h2>Αίτηση Συμμετοχής Ωφελούμενων</h2>
                      {!editMode ? (
                        <img className={classes.editIcon} src={edit_filled} alt="edit" onClick={toggleEditMode} style={{ height: 48 }} />
                      ) : (
                        <img className={classes.editIcon} src={saveIcon} alt="save" onClick={submitUpdate} style={{ height: 48 }} />
                      )}

                    </div>
                    <br />
                  </Col>
                </Row>


                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Επωνυμία επιχείρησης</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="company_name">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.company_name}</span>
                      ) : (
                        <Input placeholder={formValues.company_name}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              company_name: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                    {/* <span className={classes.cres}>{application[0].company_name}</span>
                    <br /> */}
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>ΑΦΜ</span>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="tax_id">
                      <span className={classes.cres}>{formValues.tax_id}</span>
                      {/* {!editMode ? (
                        <span className={classes.cres}>{formValues.tax_id}</span>
                      ) : (
                        <Input placeholder={formValues.tax_id} className={classes.mIdInput} />
                      )} */}
                    </Form.Item>
                    {/* <span className={classes.cres}>{application[0].tax_id}</span>
                    <br /> */}
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Δραστηριότητα Επιχείρησης</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="activity">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.activity}</span>
                      ) : (
                        <Dropdown overlay={menu} trigger={['click']} className={classes.menuActivities}>
                          <a onClick={(e) => e.preventDefault()}>
                            {selectedActivity !== null
                              ? `${activities.find((a) => a.recid === selectedActivity)?.value}`
                              : formValues.activity || 'Select an activity'}{' '}
                            <DownOutlined />
                          </a>
                        </Dropdown>
                        // <Input placeholder={formValues.activity} className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Διεύθυνση Επιχείρησης</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="address">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.address}</span>
                      ) : (
                        <Input placeholder={formValues.address}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              address: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                    {/* <span className={classes.cres}>{application[0].address + " " + application[0].address_number}</span> */}

                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Αριθμός Διεύθυνσης Επιχείρησης</span>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="adress_number">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.address_number}</span>
                      ) : (
                        <Input placeholder={formValues.address_number}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              address_number: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>ΤΚ</span>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="zipcode">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.zipcode}</span>
                      ) : (
                        <Input placeholder={formValues.zipcode}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              zipcode: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Πόλη</span>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="location">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.location}</span>
                      ) : (
                        <Input placeholder={formValues.location}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              location: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Περιφερειακή Ενότητα (Νομός)</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="region">
                      {!editMode ? (
                        <span className={classes.cres}>-</span>
                      ) : (
                        <Input placeholder={formValues.region} className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>ΔΟΥ</span>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="tax_office">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.tax_office}</span>
                      ) : (
                        <Input placeholder={formValues.tax_office}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              tax_office: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Σταθερό Τηλέφωνο</span>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="phone1">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.phone1}</span>
                      ) : (
                        <Input placeholder={formValues.phone1}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              phone1: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Κινητό Τηλέφωνο</span>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="phone2">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.phone2}</span>
                      ) : (
                        <Input placeholder={formValues.phone2}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              phone2: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={10} lg={10} xl={8}>
                    <span className={classes.clabel}>Ε-mail</span>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={16}>
                    <Form.Item label="" name="email">
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.email}</span>
                      ) : (
                        <Input placeholder={formValues.email}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              email: inputValue.toString(),
                            });
                          }}
                          className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                    <span className={classes.clabel}>Συννημένα Δικαιολογητικά</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14} xl={16}>
                    <div className={classes.fileContainer}>
                      <span className={classes.cresFileTitle}>Αρχείο (1): </span>
                      {application[0].file1 ? <span className={classes.cresFile}>{application[0].file1}</span> : <span className={classes.cresFile}>-</span>}
                      <div className={classes.fileButtons}>
                        {application[0].file1 ? <img src={download} alt="download" className={classes.imgDownload} onClick={() => downloadFile('file1')} /> : ''}
                        {/* <input type="file" onChange={handleFileChange} />
                      <img src={upload} alt="upload" className={classes.imgDownload} onClick={() => uploadFile('file1')} /> */}
                        <img src={upload} alt="upload" className={classes.imgDownload} onClick={() => toggleUpload('file1')} />
                      </div>
                    </div>

                    {/* <div className={classes.fileContainer}>
                      <span className={classes.cresFileTitle}>Αρχείο (2): </span>
                      {application[0].file2 ? <span className={classes.cresFile}>{application[0].file2}</span> : <span className={classes.cresFile}>-</span>}

                      <div className={classes.fileButtons}>
                        {application[0].file2 ? <img src={download} alt="download" className={classes.imgDownload} onClick={() => downloadFile('file2')} /> : ''}
                        <img src={upload} alt="upload" className={classes.imgDownload} onClick={() => toggleUpload('file2')} />
                      </div>
                    </div>
                    <div className={classes.fileContainer}>
                      <span className={classes.cresFileTitle}>Αρχείο (3): </span>
                      {application[0].file3 ? <span className={classes.cresFile}>{application[0].file3}</span> : <span className={classes.cresFile}>-</span>}
                      <div className={classes.fileButtons}>
                        {application[0].file3 ? <img src={download} alt="download" className={classes.imgDownload} onClick={() => downloadFile('file3')} /> : ''}
                        <img src={upload} alt="upload" className={classes.imgDownload} onClick={() => toggleUpload('file3')} />
                      </div>
                    </div>
                    <div className={classes.fileContainer}>
                      <span className={classes.cresFileTitle}>Αρχείο (4): </span>
                      {application[0].file4 ? <span className={classes.cresFile}>{application[0].file4}</span> : <span className={classes.cresFile}>-</span>}
                      <div className={classes.fileButtons}>
                        {application[0].file4 ? <img src={download} alt="download" className={classes.imgDownload} onClick={() => downloadFile('file4')} /> : ''}
                        <img src={upload} alt="upload" className={classes.imgDownload} onClick={() => toggleUpload('file4')} />
                      </div>
                    </div>
                    <div className={classes.fileContainer}>
                      <span className={classes.cresFileTitle}>Αρχείο (5): </span>
                      {application[0].file5 ? <span className={classes.cresFile}>{application[0].file5}</span> : <span className={classes.cresFile}>-</span>}
                      <div className={classes.fileButtons}>
                        {application[0].file5 ? <img src={download} alt="download" className={classes.imgDownload} onClick={() => downloadFile('file5')} /> : ''}
                        <img src={upload} alt="upload" className={classes.imgDownload} onClick={() => toggleUpload('file5')} />
                      </div>
                    </div> */}
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <br />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <span className={classes.clabel3}>Microsoft ID</span>
                    <Form.Item label="" name="microsoft_id">
                      {!editMode ? (
                        <Input
                          className={classes.mIdInput}
                          // disabled
                          placeholder={application[0].microsoft_id}
                          // suffix={
                          //   <Tooltip title="Μη επεξεργάσιμο">
                          //     <img src={lock} alt="lock" />
                          //   </Tooltip>
                          // }
                        />
                      ) : (

                        <Input
                          className={classes.mIdInput}
                          placeholder={application[0].microsoft_id}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              microsoft_id: inputValue.toString(),
                            });
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <br />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <span className={classes.clabel3}>Επιθυμητό User ID</span>
                    <Form.Item label="" name="microsoft_username">
                      {!editMode ? (
                        <Input
                          className={classes.mIdInput}
                          placeholder={formValues.microsoft_username}
                        />
                      ) : (
                        <Input
                          className={classes.mIdInput}
                          placeholder={formValues.microsoft_username}
                          onChange={async (event) => {
                            const inputValue = event.target.value;
                            await setFormValues({
                              ...formValues,
                              microsoft_username: inputValue.toString(),
                            });
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <br />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <span className={classes.clabel3}>Συνολικό Κόστος  De Minimis</span>
                    <Form.Item label="" name="sorefsis_amount">
                      {!editMode ? (
                        <Input
                          className={classes.mIdInput}
                          disabled
                          // placeholder={application[0].sorefsis_amount.toString()}
                          value={formValues.sorefsis_amount}
                          placeholder={formValues.sorefsis_amount}
                          suffix={
                            <Tooltip title="Μη επεξεργάσιμο">
                              <img src={lock} alt="lock" />
                            </Tooltip>
                          }
                        />
                      ) : (
                        <Input
                          className={classes.mIdInput}
                          placeholder={formValues.sorefsis_amount}
                          value={formValues.sorefsis_amount}
                          onChange={(event) => {
                            const inputValue = event.target.value;

                            // Replace commas with an empty string to allow the use of commas for better readability
                            const valueWithoutCommas = inputValue.replace(/,/g, '');

                            // Normalize the input value by keeping the period before the last one or two digits
                            const normalizedValue = valueWithoutCommas.replace(/(\d+)\.?(\d{1,2})$/, '$1.$2');

                            // Convert the normalized value with dots to a numeric value
                            const numericValue = parseFloat(normalizedValue);

                            // Check if the numeric value is valid
                            if (!isNaN(numericValue)) {
                              // Update the form value with the unformatted numeric value as a string
                              setFormValues({
                                ...formValues,
                                sorefsis_amount: numericValue.toString(),
                              });
                            } else {
                              // If the numeric value is not valid, update the form value with the raw input
                              setFormValues({
                                ...formValues,
                                sorefsis_amount: inputValue,
                              });
                            }
                          }}
                        />

                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <br />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <span className={classes.clabel3} style={{ marginTop: 0 }}>Περιοχή</span>
                    <br />
                    <Form.Item label="" name="area_data">

                      <Input
                        className={classes.mIdInput}
                        disabled
                        placeholder={areaStatus ? 'Εντός Ορίων' : "Εκτός Ορίων"}
                        suffix={
                          <Tooltip title="Μη επεξεργάσιμο">
                            <img src={lock} alt="lock" />
                          </Tooltip>
                        }
                      />

                    </Form.Item>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <br />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <Row className={classes.buttonsContainer}>
                      <Col xl={8}>
                        <Button type="primary" className={classes.delete} style={{ backgroundColor: '#263489' }} htmlType="submit" onClick={toggleEdit} >
                          <img src={edit_transparent} alt="" />
                          Επεξεργασία  Κατάστασης
                        </Button>
                      </Col>
                      <Col xl={8}>
                        <Button type="primary" className={classes.delete} style={{ backgroundColor: '#ED5350' }} htmlType="submit" onClick={toggleDeletion}>
                          <img src={del} alt="" />
                          Διαγραφή αίτησης
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={10} md={9} lg={24} xl={8}></Col>
                  <Col xs={24} sm={14} md={15} lg={24} xl={16}>
                    <br /><br /><br />
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <br />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    {status === 0 && (
                      <div className={classes.notificationContainer}>
                        <img src={danger} alt="danger" />
                        <span>
                          <strong>Υπενθύμιση:</strong> Η συνδρομή σας έχει διακοπεί. Από 29/04/23 δεν θα έχετε πλέον πρόσβαση στις δωρεάν παροχές.
                        </span>
                      </div>
                    )}

                    {application[0].status_id === 1 && (
                      <div className={classes.notificationContainer}>
                        <img src={exclamation} alt="danger" className={classes.statusImg} />
                        <span style={{ color: '#FED540' }}>
                          Η Αίτηση βρίσκεται σε εξέλιξη
                        </span>
                      </div>
                    )}
                    {application[0].status_id === 2 && (
                      <div className={classes.notificationContainer} style={{ backgroundColor: '#EFFAF5' }}>
                        <img src={success} alt="danger" className={classes.statusImg} />
                        <span style={{ color: '#33C481' }}>
                          Εγκεκριμένη Αίτηση
                        </span>
                      </div>
                    )}
                    {application[0].status_id === 4 && (
                      <div className={classes.notificationContainer} style={{ backgroundColor: '#FEF1F1' }}>
                        <img src={fail} alt="danger" className={classes.statusImg} />
                        <span style={{ color: '#ED5350' }}>
                          Μη Εγκεκριμένη Αίτηση
                        </span>
                      </div>
                    )}


                    {/* <TextArea rows={18} className={classes.textArea} />
                  <div className={classes.lockIcon}>
                    <Tooltip title="Not Editable">
                      <img src={lock} alt="lock" />
                    </Tooltip>
                  </div> */}
                    {/* <LockableTextArea disabled value={application[0].microsoft_id} locked={true} /> */}

                    {/* <div
                    // disabled
                    // suffix={
                    //   <Tooltip title="Μη επεξεργάσιμο">
                    //     <img src={lock} alt="lock" />
                    //   </Tooltip>
                    className={classes.mailInput}
                    contentEditable
                    dangerouslySetInnerHTML={{ __html: editableContent }}
                    onBlur={handleContentChange}
                  /> */}
                    {!statusEmail ?
                      <>
                        <span className={classes.clabel3}>Απάντηση προς τον αιτούμενο</span>
                        <br />
                        <div
                          className={classes.mailInput}
                          contentEditable={application[0].status_id !== 1}
                          style={{ position: 'relative' }} // Add this line to set the parent div to relative positioning
                          onBlur={(event) => setEmailBody(event.target.innerHTML)}
                        >
                          <div dangerouslySetInnerHTML={{ __html: editableContent }} />
                          {application[0].status_id === 1 ? (
                            <Tooltip title="Μη επεξεργάσιμο">
                              <img
                                src={lock}
                                alt="lock"
                                style={{
                                  position: 'absolute',
                                  bottom: 0,
                                  right: 0,
                                  padding: '3%',
                                  cursor: 'not-allowed' // Set the cursor property to 'not-allowed'
                                }}
                              />
                            </Tooltip>
                          ) : null}
                        </div>
                        {/* <Row>
                          <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                            <span className={classes.clabel}>Συν. Αρχεία</span>
                            <br />
                          </Col>
                          <Col xs={24} sm={17} md={18} lg={14} xl={16}>
                            <Form.Item label="" name="emailfiles" className={classes.fileList}>
                              <Upload
                                listType="text"
                                beforeUpload={(file: File) => false}
                                onChange={(info) => handleEmailFileChange(info, setFile1)}
                              >
                                <Button type="primary" className={classes.delete} style={{ backgroundColor: '#7BB6E1' }} >
                                  <UploadOutlined />
                                  Ανέβασμα αρχείων
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row> */}
                        <br />
                        <Button
                          type="primary"
                          className={classes.forward}
                          style={{ backgroundColor: '#0000003D' }}
                          htmlType="submit"
                          onClick={sentEmail}
                          disabled={application[0].status_id === 1}
                        >
                          <img src={mail} alt="" />
                          Αποστολή email
                        </Button>
                      </> :
                      <>
                        <br /><br />

                        <div className={classes.notificationContainer} style={{ backgroundColor: 'rgb(123 182 225 / 7%)' }}>
                          <img src={mail_success} alt="danger" className={classes.statusImg} style={{ maxWidth: 32, maxHeight: 32, paddingLeft: 12 }} />
                          <span style={{ color: '#7BB6E1', paddingLeft: 5 }}>
                            Έχει αποσταλεί το email στον αιτούμενο.
                          </span>
                        </div>
                        <br />
                      </>
                    }
                  </Col>
                </Row>

              </Col>

            </Row>
          </Form>
        </div >
      </div >
      {/* {deletion && <div className={classes.notificationContainer}>
        <img src={danger} alt="danger" />
        <span>
          <strong>Υπενθύμιση:</strong>  Η συνδρομή σας έχει διακοπεί. Από 29/04/23 δεν θα έχετε πλέον πρόσβαση στις δωρεάν παροχές.
        </span>
      </div>} */}



    </>
  )
}

export default Profile
